// extracted by mini-css-extract-plugin
export var footer = "G_cl";
export var footerBottomLinks = "G_cw";
export var footerContactBar = "G_cq";
export var footerContactBarChannels = "G_cs";
export var footerContactBarChannelsPage = "G_cv";
export var footerContactBarChannelsSocial = "G_ct";
export var footerContactBarLogo = "G_cr";
export var footerNav = "G_cm";
export var footerNavList = "G_cn";
export var footerNavListHeadline = "G_cp";